export const compressImageTo1MB = async (file: File, sizeDividend: number = 1) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event: any) {
            const img: any = new Image();
            img.onload = function () {
                const canvas = document.createElement("canvas");
                const ctx: any = canvas.getContext("2d");
                const newWidth = img.width / sizeDividend;
                const newHeight = img.height / sizeDividend;

                canvas.width = newWidth;
                canvas.height = newHeight;
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(
                    async (blob: any) => {
                        blob.name = file.name;
                        blob.lastModified = file.lastModified;

                        if (blob.size < 1 * 1024 * 1024) {
                            // If blob size is less than 1 MB, resolve with the blob
                            resolve(blob);
                        } else {
                            // If blob size is greater than or equal to 1 MB, recursively call compressImageTo1MB with updated sizeDividend
                            const nextSizeDividend = sizeDividend + 0.1;
                            const nextBlob = await compressImageTo1MB(file, nextSizeDividend);
                            resolve(nextBlob);
                        }
                    },
                    file.type,
                    0.1
                );
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    });
};
