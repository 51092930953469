const IDs = {
    lorryPosting: {
        lorryPosting_myLorries: "myLories", // already there
        lorryPosting_postLorryBtn: "dashboard_btn_post", // already there
        lorryPosting_lorryNumInputField: "lorryPosting_lorryNumInputField", //done
        lorryPosting_lorryLocationInputField: "lorryPosting_lorryLocationInputField", //done
        lorryPosting_verifyMyVehicleCTA: "Verify_my_vehicle", //done
        lorryPosting_yesContinueCTA: "lorryPosting_yesContinueCTA", //done
        lorryPosting_selectAllStateCheckBox: "postingForm_btn_selectAllState", // already there
        lorryPosting_updateDetailsCTA: "postingForm_btn_submit", //done
        lorryPosting_selectLorryType: "1", //Check with @supritha
        lorryPosting_lorryCapacityInputField: "lorryPosting_lorryCapacityInputField", //done
        lorryPosting_browseFiles: "lorryPosting_browseFiles",
        lorryPosting_postLCV: "postingForm_btn_submit", //done
        lorryPosting_closeIconAtPostLorryModal: "lorryPosting_closeIconAtPostLorryModal", //done
        lorryPosting_viewLiveLorries: "lorryPosting_viewLiveLorries", //done
        lorryPosting_postLorryBtnOnSuccessScreen: "lorryPosting_postLorryBtnOnSuccessScreen"
    }
};

export default IDs;
