import { LORRY_CATEGORIES_ENUM, NEW_LORRY_TYPES } from "@vahak/core/dist/constants/lorryTypes";
import * as Yup from "yup";
import { lorryCreationFormFieldNames } from "../constants";
import { TruckBodyOptions } from "../../common/vehicle-type-form-fields/truck/TruckFormFields";
import { TankerBodyOptions } from "../../common/vehicle-type-form-fields/tanker/TankerFormFields";
import { getContainerSizeEnum } from "../../common/vehicle-type-form-fields/container/containerClassificationHelper";
import { getTrailerBodyEnum } from "../../common/vehicle-type-form-fields/trailer/trailerClassificationHelper";
import { getLcvSizeEnum } from "../../common/vehicle-type-form-fields/lcv/lcvClassificationHelper";
import { LcvBodyOptions } from "../../common/vehicle-type-form-fields/lcv/LcvFormFields";

/**
 * We have not added hardcore validations like if the container is a 32 ft sxl then
 * capacity can only be 7,8,9 tonne because we don't have an open field.
 *
 * Backend validations should be there.
 *
 *
 * rcDocument is disabled as in app its not mandatory field
 */

const isLoadForm = (option: any) => {
    return option?.parent?.formSource === "load";
};

export const lorryValidationSchema = {
    [lorryCreationFormFieldNames.rcDocument]: Yup.number().when("isRcUploadRequired", (state) => {
        if (state) return Yup.mixed().required("Required");
        else return Yup.mixed().notRequired();
    }),
    [lorryCreationFormFieldNames.lorryType]: Yup.number().positive("Lorry type is required!"),
    [lorryCreationFormFieldNames.lorryCapacity]: Yup.number().when(
        [lorryCreationFormFieldNames.lorryType],
        (...args: any) => {
            const [lorryType, _, option] = args;

            const selectedLorry = NEW_LORRY_TYPES.filter((x) => x.id === lorryType)[0];

            return Yup.number().when([lorryCreationFormFieldNames.isLorryCapacityRequired], {
                is: true,
                then: selectedLorry
                    ? Yup.number()
                          .typeError("Please enter a valid number")
                          .min(selectedLorry.capacity.min, `Invalid capacity (Range: ${selectedLorry.capacityText})`)
                          .max(selectedLorry.capacity.max, `Invalid capacity (Range: ${selectedLorry.capacityText})`)
                          .required("Lorry Capacity is required field")
                    : Yup.number()
                          .typeError("Please enter a valid number")
                          .min(1, `Lorry Capacity can't be less than 1`)
                          .max(100, `Lorry Capacity can't be more than 100`)
                          .required("Lorry Capacity is required field")
            });
        }
    ),

    // [lorryCreationFormFieldNames.lorryCapacity]: Yup.number().when(
    //     [
    //         lorryCreationFormFieldNames.lorryType,
    //         lorryCreationFormFieldNames.lorryTyres,
    //         lorryCreationFormFieldNames.lorrySize,
    //         lorryCreationFormFieldNames.sizeText
    //     ],
    //     (...args: any) => {
    //         const [lorryType, lorryTyres, lorrySize, lorrySizeText, _, option] = args;
    //         /**
    //          * Lorry Capacity is present in all except in Trailer
    //          */

    //         const selectedLorry = NEW_LORRY_TYPES.filter((x) => x.id === lorryType)[0];

    //         const isTruck = lorryType === LORRY_CATEGORIES_ENUM.TRUCK;
    //         const isContainer = lorryType === LORRY_CATEGORIES_ENUM.CONTAINER;
    //         const isTrailer = lorryType === LORRY_CATEGORIES_ENUM.TRAILER;
    //         const isLcv = lorryType === LORRY_CATEGORIES_ENUM.LCV;
    //         const isHyva = lorryType === LORRY_CATEGORIES_ENUM.HYVA;
    //         const isTanker = lorryType === LORRY_CATEGORIES_ENUM.TANKER;

    //         let selectedLorryCapacity;
    //         let selectedLorryCapacityText = selectedLorry ? selectedLorry.capacityText : undefined;

    //         if (isTruck) {
    //             const lorry = selectedLorry?.tyreWithCapacity?.find((x) => x.id === lorryTyres);
    //             selectedLorryCapacity = lorry?.capacity;
    //             selectedLorryCapacityText = lorry?.capacityText;
    //         } else if (isContainer) {
    //             const lorry = selectedLorry?.bodyTypeWithCapacity?.find(
    //                 (x) => x.id === getContainerSizeEnum(lorrySize, lorrySizeText)
    //             );
    //             selectedLorryCapacity = lorry?.capacity;
    //             selectedLorryCapacityText = lorry?.capacityText;
    //         } else if (isTrailer) {
    //             const lorry = selectedLorry?.bodyTypeWithCapacity?.find(
    //                 (x) => x.id === getTrailerBodyEnum(lorrySize, lorrySizeText)
    //             );
    //             selectedLorryCapacity = lorry?.capacity;
    //             selectedLorryCapacityText = lorry?.capacityText;
    //         } else if (isLcv) {
    //             const lorry = selectedLorry?.bodyTypeWithCapacity?.find((x) => x.id === getLcvSizeEnum(lorrySize));
    //             selectedLorryCapacity = lorry?.capacity;
    //             selectedLorryCapacityText = lorry?.capacityText;
    //         } else if (isHyva) {
    //             const lorry = selectedLorry?.tyreWithCapacity?.find((x) => x.id === lorryTyres);
    //             selectedLorryCapacity = lorry?.capacity;
    //             selectedLorryCapacityText = lorry?.capacityText;
    //         } else if (isTanker) {
    //             const lorry = selectedLorry?.tyreWithCapacity?.find((x) => x.id === lorryTyres);
    //             selectedLorryCapacity = lorry?.capacity;
    //             selectedLorryCapacityText = lorry?.capacityText;
    //         }

    //         if (lorryType !== LORRY_CATEGORIES_ENUM.TRAILER)
    //             return Yup.number().when([lorryCreationFormFieldNames.isLorryCapacityRequired], {
    //                 is: true,
    //                 then: selectedLorryCapacity
    //                     ? Yup.number()
    //                           .typeError("Please enter a valid number")
    //                           .min(selectedLorryCapacity.min, `Invalid capacity (Range: ${selectedLorryCapacityText})`)
    //                           .max(selectedLorryCapacity.max, `Invalid capacity (Range: ${selectedLorryCapacityText})`)
    //                           .required("Lorry Capacity is required field")
    //                     : Yup.number()
    //                           .typeError("Please enter a valid number")
    //                           .min(1, `Lorry Capacity can't be less than 1`)
    //                           .max(100, `Lorry Capacity can't be more than 100`)
    //                           .required("Lorry Capacity is required field")
    //             });
    //         else
    //             return Yup.number().when([lorryCreationFormFieldNames.isLorryCapacityRequired], {
    //                 is: true,
    //                 then: selectedLorryCapacity
    //                     ? Yup.number()
    //                           .typeError("Please enter a valid number")
    //                           .min(selectedLorryCapacity.min, `Invalid capacity (Range: ${selectedLorryCapacityText})`)
    //                           .max(selectedLorryCapacity.max, `Invalid capacity (Range: ${selectedLorryCapacityText})`)
    //                           .required("Lorry Capacity is required field")
    //                     : Yup.number()
    //                           .typeError("Please enter a valid number")
    //                           .min(1, `Lorry Capacity can't be less than 1`)
    //                           .max(100, `Lorry Capacity can't be more than 100`)
    //                           .required("Lorry Capacity is required field")
    //             });
    //     }
    // ),
    [lorryCreationFormFieldNames.lorryTyres]: Yup.number().when(
        [lorryCreationFormFieldNames.lorryType],
        (...args: any) => {
            const [lorryType, _, option] = args;
            /**
             * Lorry Tyres are only present in Truck, Hyva and Tanker
             */
            // if (lorryType === LORRY_CATEGORIES_ENUM.TRUCK && isLoadForm(option)) {
            //     // make Truck Tyre selection optional in load post form
            //     return Yup.number();
            // }
            if (
                lorryType === LORRY_CATEGORIES_ENUM.TRUCK ||
                lorryType === LORRY_CATEGORIES_ENUM.HYVA ||
                lorryType === LORRY_CATEGORIES_ENUM.TANKER
            )
                return Yup.number().positive("Tyres should be present");
            else return Yup.number();
        }
    ),
    [lorryCreationFormFieldNames.lorrySize]: Yup.number().when(
        [lorryCreationFormFieldNames.lorryType, lorryCreationFormFieldNames.lorryTyres],
        (...args: any) => {
            const [lorryType, lorryTyres, _, option] = args;

            if (
                (lorryType === LORRY_CATEGORIES_ENUM.TRUCK && lorryTyres === 6) ||
                lorryType === LORRY_CATEGORIES_ENUM.LCV
            ) {
                /**
                 * Lorry Size is present in Truck when there are 6 tyres
                 */
                return Yup.number().positive("Lorry Size should be present");
            } else if (lorryType === LORRY_CATEGORIES_ENUM.CONTAINER || lorryType === LORRY_CATEGORIES_ENUM.TRAILER) {
                /**
                 * Lorry Size is also present when selected lorry is a container or trailer
                 */
                return Yup.number().positive("Lorry Size should be present");
            } else return Yup.number();
        }
    ),
    [lorryCreationFormFieldNames.lorryBodyType]: Yup.number().when(
        [lorryCreationFormFieldNames.lorryType],
        (lorryType) => {
            /**
             * Body Type is only present for truck, tanker and lcv type lorry
             */
            if (lorryType === LORRY_CATEGORIES_ENUM.TRUCK)
                return Yup.number().test("", "Invalid body type", (value) => {
                    return value === TruckBodyOptions[0].id || value === TruckBodyOptions[1].id;
                });
            else if (lorryType === LORRY_CATEGORIES_ENUM.TANKER)
                return Yup.number().test("", "Invalid body type", (value) => {
                    return value === TankerBodyOptions[0].id || value === TankerBodyOptions[1].id;
                });
            else if (lorryType === LORRY_CATEGORIES_ENUM.LCV)
                return Yup.number().test("", "Invalid body type", (value) => {
                    return value === LcvBodyOptions[0].id || value === LcvBodyOptions[1].id;
                });
            else return Yup.number().nullable();
        }
    ),
    [lorryCreationFormFieldNames.sizeText]: Yup.number().when(
        [lorryCreationFormFieldNames.lorryType, lorryCreationFormFieldNames.lorryTyres],
        (lorryType, lorryTyres) => {
            /**
             * Size text is only present on Container or Trailer
             */
            if (
                lorryType === LORRY_CATEGORIES_ENUM.CONTAINER ||
                lorryType === LORRY_CATEGORIES_ENUM.TRAILER ||
                lorryType === LORRY_CATEGORIES_ENUM.LCV ||
                (lorryType === LORRY_CATEGORIES_ENUM.TRUCK && lorryTyres === 6)
            )
                return Yup.string().required("Size text is required");
            else return Yup.string().oneOf([""]);
        }
    )
};

export const lorryTypeValidation = Yup.object().shape({
    ...lorryValidationSchema
});
