import { FormikProps } from "formik";
import Button from "@vahak/core-ui/dist/components/Button";
import FileInput from "@vahak/core-ui/dist/components/FileInput";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import { toast } from "@vahak/core/dist/components/toast/toast";
import React, { useRef } from "react";
import { useState } from "react";
import {
    commonLorryFormFieldNames,
    editBulkLoadLorryDetails,
    loadCreationFormFieldsTypes,
    lorryCreationFormFieldNames,
    lorryCreationFormFieldsTypes
} from "../../../helpers/constants";

//Icons
import Remove from "@vahak/core/dist/icons/crossIconRejected.svg";
import COLORS from "@vahak/core-ui/dist/constants/colors";

import IfCanAccess from "../../../../admin/IfCanAccess/IfCanAccess";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { NEW_LORRY_TYPES } from "@vahak/core/dist/constants/lorryTypes";
import IDs from "../../../lorry-posting/automationIds/automation";
import { compressImageTo1MB } from "../../../../../methods/compressImage";

interface UploadRcProps {
    formik: FormikProps<lorryCreationFormFieldsTypes | loadCreationFormFieldsTypes | editBulkLoadLorryDetails>;
}

const UploadRc = ({ formik }: UploadRcProps) => {
    const lorryFormikValue = formik as FormikProps<lorryCreationFormFieldsTypes>;
    const [file, setFile] = useState<File | null>();

    //Events
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const handleChangeRCInput = async (newFile: File) => {
        if (newFile.size < 1 * 1024 * 1024) {
            setFile(newFile);
            formik.setFieldValue(lorryCreationFormFieldNames.rcDocument, newFile);
        } else {
            if (newFile.type.includes("pdf")) {
                toast.error("Please upload an image");
                return;
            }
            const compressedBlob = await compressImageTo1MB(newFile);
            setFile(compressedBlob as any);
            formik.setFieldValue(lorryCreationFormFieldNames.rcDocument, compressedBlob);
        }
    };

    const onSizeError = (err: string) => {
        toast.error(err);
    };

    const resetFile = () => {
        setFile(null);
        formik.setFieldValue(lorryCreationFormFieldNames.rcDocument, undefined);
    };

    return (
        <>
            <IfCanAccess action={""} allowCustomer>
                <Flex flexDirection="column" gap={20}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography
                            weight="semibold"
                            required={
                                lorryFormikValue.values.isLorryCapacityRequired ||
                                lorryFormikValue.values.manualVerification ||
                                formik.values.rcExpired
                            }
                        >
                            Upload RC Document
                        </Typography>
                        {file ? (
                            <Button fillType="text" onClick={resetFile} startIcon={<Remove />} type="button">
                                <Typography color={COLORS.RED_400}>Remove</Typography>
                            </Button>
                        ) : (
                            <></>
                        )}
                    </div>
                    <FileInput
                        id={IDs.lorryPosting.lorryPosting_browseFiles}
                        label=""
                        onChange={handleChangeRCInput}
                        selectedFile={file}
                        ctaText="Browse"
                        onSizeError={onSizeError}
                        fileOrFiles={file}
                        fileTypes={["JPG", "PNG", "JPEG", "PDF"]}
                        onClick={() => {
                            /**
                             * Events
                             */

                            sendGAandMoEngageEvent({
                                name: GA4EventNames.LORRY_POSTING_MODAL.document_upload_clicked,
                                data: {
                                    vehicle_number: lorryFormikValue.values.lorryNum,
                                    lorry_type: lorryFormikValue.values.lorryType,
                                    current_city: lorryFormikValue.values.currentCityText?.split(",")[0],
                                    total_route: lorryFormikValue.values.routeIds.length,
                                    routes: lorryFormikValue.values.routeIds,
                                    vehicle_type: NEW_LORRY_TYPES.find(
                                        (val) => val.id === lorryFormikValue.values.lorryType
                                    )?.label,
                                    capacity: lorryFormikValue.values.lorryCapacity,
                                    body_type: lorryFormikValue.values.lorryBodyType,
                                    total_tyres: lorryFormikValue.values.lorryTyres
                                }
                            });
                        }}
                    />
                    {formik.values.rcExpired && !file ? (
                        <Typography weight="medium" size="xs" color={COLORS.RED}>
                            RC is expired, please upload RC to verify your vehicle.
                        </Typography>
                    ) : (
                        <></>
                    )}
                </Flex>
            </IfCanAccess>
        </>
    );
};

export default UploadRc;
