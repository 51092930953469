const filterObject = (obj: any, capacity: number) => {
    const filteredObj: any = {};

    for (const key in obj) {
        const { start, end } = obj[key];

        if (capacity >= start && capacity <= end) {
            filteredObj[key] = obj[key];
        }
    }

    return filteredObj;
};

export const truckTyreClassification = (
    tyresOptions: {
        id: number;
        value: string;
    }[],
    tyreCapacityMapping: {
        [key: number]: {
            start: number;
            end: number;
        };
    },
    lorryCapacity: number
) => {
    return tyresOptions.filter(
        (x) => x.id === Number(Object.keys(filterObject(tyreCapacityMapping, Math.ceil(lorryCapacity)))[0])
    );
};
